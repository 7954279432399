<template lang='pug'>
	.requisite-inn
		p.b1.mb-3 {{ $t('forms.enterInn') }}

		my-input(
			v-model="$v.inn.$model"
			:label="$t('forms.placeholder.inn')"
			:icon="'icon-terms-of-use'"
			:mask="'############'"
			:classInput="{'is-error': alreadyAttemptSend && ($v.inn.$error || error || inn && inn.length == 11)}"
			@blur="validateForm"
			@input="onPaste"
		)
		.text-danger.b4.mt-1(v-if='inn && inn.length == 11') {{ $t('errors.minLength.innAdd') }}
		.text-danger.b4.mt-1(v-else-if='!$v.inn.required && alreadyAttemptSend') {{ $t('errors.required.inn') }}
		.text-danger.b4.mt-1(v-else-if='!$v.inn.numeric && alreadyAttemptSend') {{ $t('errors.numeric.default') }}
		.text-danger.b4.mt-1(v-else-if='!$v.inn.minLength && alreadyAttemptSend')
			span {{ $t('errors.minLength.innAdd') }}
		.text-danger.b4.mt-1(v-else-if='!$v.inn.maxLength && alreadyAttemptSend')
			span {{ $t('errors.minLength.innAdd') }}
		.text-danger.b4.mt-1(v-else-if='error') {{ error }}

		.requisite-loader(v-if='!data.inn')
			loader(v-if='isPending')
		div(v-else)
			p.b1.text-grey.mt-3 {{ $t('forms.clickNext') }}
			.text-uppercase.card-info(v-if='data.inn')
				span.b3.font-bold-600.mb-1 {{ data.fullName }}
				span.b3.mb-1 {{ data.addressShown }}
				span.b3.mb-1 {{ $t('forms.placeholder.inn') }} {{ data.inn }}
				span.b3(v-if='data.kpp') {{ $t('forms.placeholder.kpp') }} {{ data.kpp }}
				span.b3(v-else) {{ $t('forms.placeholder.ogrnip') }} {{ data.ogrn }}
</template>

<script>
const { required, numeric, minLength, maxLength } = require("vuelidate/lib/validators")

import Loader from '@/components/Widgets/Loader';
import { mapActions, mapGetters } from 'vuex';
import { bus } from '@/main.js';
import MyInput from "@/components/UI/MyInput";

export default {
	name: 'RequisiteInn',
	components: {
		Loader,
		MyInput
	},

	data () {
		return {
			lastFind: null,
			inn		: null,
			error	: null,
			alreadyAttemptSend: false,
			data: {
				fullname 		: null,
				shortName		: null,
				inn 			: null,
				ogrn 			: null,
				kpp 			: null,
				address 		: null,
				addressesStreet : null,
				addressesCity 	: null,
				addressesZip 	: null,
				addressShown	: null
			}
		}
	},
	validations() {
		return {
			inn : {required, numeric, minLength: minLength(10), maxLength: maxLength(12)}
		}
	},
	computed: {
		...mapGetters({
			isPending 	: 'finance/isPendingINN',
		})
	},
	methods: {
		...mapActions({
			findCompany		: 'finance/findCompany'
		}),
		async validateForm () {
			this.$v.$touch();
			this.alreadyAttemptSend = true;
			if( this.inn.length == 11 ) return false;
			if( this.$v.$anyError ) return false;

			this.find();
			return true;
		},
		onPaste () {
			setTimeout( () => this.validateForm(), 100 );
		}
	},
	created () {
		bus.$on('validateInn', this.validateForm);
		this.find = _.debounce( ( data = {} ) => {
			if ( this.lastFind == this.inn ) return;

			this.error = null;
			this.lastFind = this.inn;
			_.each(this.data, (d,i) => this.data[i] = null);
			this.findCompany({query: this.inn})
				.then( v => {
					this.$emit('onFind', null);

					if ( v.length == 0 ) return this.error = this.$t('errors.noFound.company');
					let company = _.find(v, vv => vv.data.state.status != 'LIQUIDATED');
					if ( !company ) return this.error = this.$t('errors.noFound.company');
					let value = company.data;

					this.data.fullName 			= value.name.full_with_opf;
					this.data.shortName			= value.name.short_with_opf;
					this.data.inn 				= value.inn;
					this.data.ogrn 				= value.ogrn;
					this.data.kpp 				= value.kpp;
					this.data.addressShown		= value.address != null ? _.compact([value.address.data.postal_code, value.address.value.replace(value.address.data.postal_code, '')]).join(', ') : null;
					this.data.address 			= value.address != null ? value.address.value : null;
					this.data.addressesStreet 	= value.address != null ? _.compact([value.address.data.street_with_type, value.address.data.house_type ? value.address.data.house_type + ' ' + value.address.data.house : null, value.address.data.flat_type ? value.address.data.flat_type + ' ' + value.address.data.flat : null]).join(', ') : null;
					this.data.addressesCity 	= value.address != null ? _.uniq(_.compact([value.address.data.region_with_type, value.address.data.area_with_type, value.address.data.city_with_type, value.address.data.settlement_with_type])).join(', ') : null;
					this.data.addressesZip 		= value.address != null ? value.address.data.postal_code : null;
					this.data.type 				= value.type;

					this.error = null;

					if (value.type === 'INDIVIDUAL') {
						let a = value.fio.name.split(' ');
						this.data.firstName  = a.length > 1 ? a[0] : value.fio.name;
						this.data.lastName   = value.fio.surname;
						this.data.middleName = value.fio.patronymic ? value.fio.patronymic : a.length > 1 ? a[1] : null;

						this.data.address  	= null;
						this.data.addressesStreet = null;
						this.data.addressShown = null;
					}
					else if ( value.management != null ) {
						let a = value.management.name.split(' ');
						this.data.lastName 		= a[0];
						this.data.firstName		= a[1];
						this.data.middleName 	= a[2];
					}

					this.$emit('onFind', this.data);
				}).catch( v => {
					this.error = v;
					this.$emit('onFind', null)
				})
		}, 500)
	},
	destroyed () {
		bus.$off('validateInn', this.validateForm);
	}
}
</script>
