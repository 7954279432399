<template lang='pug'>
	.requisite-list.mb-4
		p.b1.mb-3 {{ $t('finance.confirmRequisites') }}

		.disclaimer.warning.b3.mb-4(v-if='list.type.value == "INDIVIDUAL"') {{ $t('finance.attentionRequisitesIP') }}
		.disclaimer.warning.b3.mb-4(v-else) {{ $t('finance.attentionRequisites') }}

		.requisite-list__card
			span {{ $t('forms.placeholder.fullName') }}
			span.text-break {{ list.fullName }}
		.requisite-list__card
			span {{ $t('forms.placeholder.companyShort') }}
			span.text-break {{ list.shortName }}
		.requisite-list__card
			span {{ $t('forms.placeholder.inn') }}
			span.text-break {{ list.inn }}
		.requisite-list__card(v-if='list.kpp')
			span {{ $t('forms.placeholder.kpp') }}
			span.text-break {{ list.kpp }}
		.requisite-list__card
			span {{ $t('forms.placeholder.ogrn') }}
			span.text-break {{ list.ogrn }}
		.requisite-list__card
			span(v-if='list.type.value == "INDIVIDUAL"') {{ $t('forms.placeholder.ownerAddress') }}
			span(v-else) {{ $t('forms.placeholder.companyAddress') }}
			span.text-break {{ list.addressShown ? list.addressShown : (list.addressesStreet ? list.addressesStreet : list.adress) }}
		.requisite-list__card
			span {{ $t('forms.email') }}
			span.text-break {{ list.email }}
		.requisite-list__card
			span {{ $t('forms.phone') }}
			span.text-break {{ list.phone }}

		p.b1.mb-0.mt-4 {{ $t('finance.accountTypes[1].requisitesOwner') }}
		.requisite-list__card
			span {{ $t('forms.placeholder.fio') }}
			span.text-break {{ list.lastName }} {{ list.firstName }} {{ list.middleName }}
		.requisite-list__card
			span {{ $t('forms.placeholder.birthday') }}
			span.text-break {{ list.birthDate.day }}.{{ list.birthDate.month }}.{{ list.birthDate.year }}
</template>

<script>
export default {
	props: {
		list: {
			type: Object,
			default: (() => {})
		}
	}
}
</script>

<style lang="scss">
@import '~@/assets/css/sass/_mixins.scss';

.requisite-list {
	&__card {
		display: flex;
		align-items: center;
		min-height: 46px;
		padding: 12px 0;
		border-bottom: 1px solid var(--grayscale-border);

		@include respond-below(sm) {
			flex-direction: column;
			align-items: start;
			padding: 15px 0;
		}

		span {
			&:first-child {
				width: 49%;
				font-size: 14px;
				line-height: 22px;
				color: var(--grey-text-color);
				margin-right: 6px;

				@include respond-below(sm) {
					width: 100%;
					margin: 0 0 10px;
				}
			}

			&:last-child {
				width: 51%;
				font-size: 14px;
				line-height: 22px;
				border-bottom: none;

				@include respond-below(sm) {
					width: 100%;
				}
			}
		}
	}

	.border-none {
		border-bottom: none;
	}
}
</style>
