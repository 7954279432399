<template lang='pug'>
	.requisite-account-number
		.b1.mb-3 {{ $t('errors.required.corrNumber') }}

		my-input(
			v-model.trim="$v.bankAccountNumber.$model"
			:label="$t('forms.placeholder.companyAccount')"
			:icon="'icon-money'"
			mask="####################"
			:classInput="{'is-error': alreadyAttemptSend && $v.bankAccountNumber.$error}"
			:isError='alreadyAttemptSend && $v.bankAccountNumber.$error'
			@blur="validateForm"
			@input="onPaste"
		)
		.text-danger.b4.mt-1(v-if='!$v.bankAccountNumber.required && alreadyAttemptSend') {{ $t('errors.required.companyAccount') }}
		.text-danger.b4.mt-1(v-else-if='!$v.bankAccountNumber.numeric && alreadyAttemptSend') {{ $t('errors.numeric.default') }}
		.text-danger.b4.mt-1(v-else-if='!$v.bankAccountNumber.minLength && alreadyAttemptSend') {{ $t('errors.minLength.account') }}
		.text-danger.b4.mt-1(v-else-if='!$v.bankAccountNumber.maxLength && alreadyAttemptSend') {{ $t('errors.minLength.account') }}
		.text-danger.b4.mt-1(v-else-if='!$v.bankAccountNumber.individual && alreadyAttemptSend') {{ $t('errors.accountInvidiual') }}
		//- .text-danger.b4.mt-1(v-else-if='!isOrganization && !$v.bankAccountNumber.notindividual && alreadyAttemptSend') {{ $t('errors.accountNotInvidiual') }}

		p.b3.mt-4.mb-4.disclaimer.error(v-if='!$v.bankAccountNumber.individual && alreadyAttemptSend') {{ $t('finance.currentOrganization') }}
</template>

<script>
const { required, numeric, minLength, maxLength } = require("vuelidate/lib/validators");

import { mapActions } from 'vuex';
import {bus} from '@/main.js';
import MyInput from "@/components/UI/MyInput";

const individual = (value) => {
	return !(/^40817/.test(value));
}

export default {
	name: 'RequisiteAccountNumber',
	components: {
		MyInput
	},
	props: {
		data: {
			type: Object,
			default: (()=> {})
		},
		isOrganization : {
			type: Boolean,
			default: false,
		}
	},
	data () {
		return {
			bankAccountNumber : null,
			alreadyAttemptSend: false,
		}
	},
	validations() {
		let valid =  {
			bankAccountNumber : {required, numeric, minLength: minLength(20), maxLength: maxLength(20), individual: individual},
		}
		
		return valid;
	},
	methods : {
		...mapActions({
			findBank		: 'finance/findBank'
		}),
		validateForm () {
			this.$v.$touch();
			this.alreadyAttemptSend = true;

			if( this.$v.$anyError ) return false;

			this.$emit('onSuccess', this.$v.bankAccountNumber.$model)
			return true;
		},
		onPaste () {
			if ( this.isMobile )
				setTimeout( () => this.validateForm(), 100 );
		}
	},
	created () {
		bus.$on('validateBankAccountNumber', this.validateForm);
	},
	destroyed () {
		bus.$off('validateBankAccountNumber', this.validateForm);
	}
}
</script>

<style lang="scss">
@import '~@/assets/css/sass/_mixins.scss';

.requisite-account-number {
	&__card {
		display: flex;
		align-items: center;
		min-height: 46px;
		padding: 12px 0;
		border-bottom: 1px solid var(--grayscale-border);

		@include respond-below(sm) {
			flex-direction: column;
			align-items: start;
			padding: 15px 0;
		}

		span {
			&:first-child {
				width: 49%;
				font-size: 14px;
				line-height: 22px;
				color: var(--grey-text-color);
				margin-right: 6px;

				@include respond-below(sm) {
					width: 100%;
					margin: 0 0 10px;
				}
			}

			&:last-child {
				width: 51%;
				font-size: 14px;
				line-height: 22px;
				border-bottom: none;

				@include respond-below(sm) {
					width: 100%;
				}
			}
		}
	}

	.border-none {
		border-bottom: none;
	}
}
</style>
