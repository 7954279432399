<template lang='pug'>
	.add-shop
		requisite-inn.mt-4(v-show='step == 0' @onFind='findedInn' :showRules='showRules')
		requisite-addons.mt-4(v-if='step == 1' @onConfirm='confirmAddons' :list='form')
		requisite-list(v-if='step == 2' :list='form')
		requisite-bik.mt-4(v-show='step == 3' @onFind='findedBik')
		requisite-account-number(v-if='step == 4' :isOrganization='isOrganization' @onSuccess='findedAccountNumber' :data='form')
		requisite-info(v-if='step == 5' :data='form')

		rules-confirm.mb-2.mt-4(@isChecked='confirmRulesChaned' :isConfirmed='isRulesChecked' v-if='rerenderRules' v-show='step == 0 && showRules && form.inn')
		.mt-4(v-if='!(step == 0 && showRules && form.inn)')

		.d-flex
			.btn.btn-border.w-30.mr-3(@click="prev") {{$t('wizard.prev')}}
			.btn.btn-primary.w-70(@click="next" :class="{'disabled': disabledBtnNext || !isRulesChecked}") {{$t('wizard.continue')}}
</template>

<script>
const { required, numeric, minLength, maxLength } = require("vuelidate/lib/validators")

import RequisiteInn from '@/components/Form/RequisiteInn'
import RequisiteBik from '@/components/Form/RequisiteBik'
import RequisiteAccountNumber from '@/components/Form/RequisiteAccountNumber'
import RequisiteAddons from '@/components/Form/RequisiteAddons'
import RequisiteList from '@/components/Form/RequisiteList'
import RequisiteInfo from '@/components/Form/RequisiteInfo'
import RulesConfirm from '@/components/Form/RulesConfirm'

import {PAY_SYSTEM, PAY_SYSTEM_TYPES} from '@/models/account'
import { mapActions, mapGetters } from 'vuex'
import {bus} from '@/main.js'
import moment from 'moment-timezone'

export default {
	name: 'FormAddShop',
	components: {
		RequisiteInn,
		RequisiteBik,
		RequisiteAddons,
		RequisiteList,
		RequisiteAccountNumber,
		RequisiteInfo,
		RulesConfirm
	},
	props : {
		account : {
			type: Object,
			default: (() => {})
		},
		showRules : {
			type: Boolean,
			default: false,
		},
		isOrganization : {
			type: Boolean,
			default: false,
		}
	},
	data () {
		return {
			step 	: 0,
			isRulesChecked : false,
			rerenderRules: true,
			tryNextStep: false,
			subTypes: [PAY_SYSTEM[1], PAY_SYSTEM[2]],
			form 	: {
				fullName		: null,
				shortName		: null,
				addressesStreet : null,
				phone 			: '+7 (',
				email 			: null,
				inn 			: null,
				kpp 			: null,
				firstName 		: null,
				lastName		: null,
				middleName		: null,
				ogrn			: null,
				addressesCity 	: null,
				addressesZip 	: null,
				bankAccountBik	: null,
				bankAccountName : null,
				bankAccountNumber: null,
				birthDate		: null,
			},
		}
	},
	computed: {
		...mapGetters({
			isPending 	: 'finance/isPending',
		}),
		disabledBtnNext () {
			return this.isPending ||
				(this.step == 0 && !this.form.inn) ||
				(this.step == 1 && (!this.form.addressesStreet || !this.form.address || !this.form.addressesZip || !this.form.lastName || !this.form.firstName || !this.form.middleName || !this.form.email || !this.form.phone || this.form.phone == '+7 (' || !this.form.birthDate)) ||
				(this.step == 3 && !this.form.bankAccountBik) ||
				(this.step == 4 && !this.form.bankAccountNumber);
		}
	},
	methods : {
		...mapActions({
			findCompany		: 'finance/findCompany'
		}),

		confirmRulesChaned ( v ) {
			this.isRulesChecked = v;
		},
		next () {
			if ( this.step == 0 ) {
				if( this.form.inn ) {
					if ( this.showRules && !this.isRulesChecked ) {
						this.tryNextStep = true;
						return false;
					}
					this.step++;
					this.$emit('stepNext');
				}

				return this.$emit('validateInn');
			}

			if ( this.step == 1 ) {
				this.step++;
				this.$emit('stepNext');
				return;
			}

			if ( this.step == 5 ) {
				if( this.form.bankAccountNumber ) {
					return this.$emit('onSuccess', this.prepareData(this.form));
				}
				this.$emit('stepNext');
			}

			this.step++;
			this.$emit('stepNext');
		},
		prev () {
			if ( this.step == 0 ) return this.$emit('onBack');
			if ( this.step == 1 ) {
				this.tryNextStep = false;
			}
			if ( this.step == 5 ) {
				this.form.bankAccountNumber = null;
			}

			this.step--;
			this.$emit('stepPrev');
		},
		findedInn ( value ) {
			this.rerenderRules = false;
			this.isRulesChecked = false;
			this.$nextTick( () => this.rerenderRules = true);

			_.each(this.form, (f,i) => this.form[i] = null);
			if ( value == null ) return;

			this.form.inn 				= value.inn;
			this.form.ogrn 				= value.ogrn;
			this.form.kpp 				= value.kpp;
			this.form.fullName 			= value.fullName;
			this.form.shortName			= value.shortName;
			this.form.address 			= value.address;
			this.form.addressesStreet 	= value.addressesStreet;
			this.form.addressesCity 	= value.addressesCity;
			this.form.addressesZip 		= value.addressesZip;
			this.form.type 				= {value: value.type == this.subTypes[1] ? this.subTypes[1] : this.subTypes[0] };
			this.form.lastName 			= value.lastName;
			this.form.firstName			= value.firstName;
			this.form.middleName 		= value.middleName;
			this.form.addressShown 		= value.addressShown;
			this.form.needEditAddress 	= !value.addressesStreet;

			if ( !this.form.lastName || this.form.lastName.length == 0 ) this.form.needEditLastName = true;
			if ( !this.form.firstName || this.form.firstName.length == 0 ) this.form.needEditFirstName = true;
			if ( !this.form.middleName || this.form.middleName.length == 0 ) this.form.needEditMiddleName = true;
		},
		findedBik ( value ) {
			this.form.bankAccountBik	= null;
			this.form.bankAccountName 	= null;
			this.form.bankCorrAccountNumber	= null;

			if ( value == null ) return;

			this.form.bankAccountBik	= value.bik;
			this.form.bankAccountName 	= value.fullname;
			this.form.bankCorrAccountNumber	= value.corAccount;
		},
		findedAccountNumber ( value ) {
			this.form.bankAccountNumber = value;
		},
		confirmAddons ( v ) {
			this.form.phone				= v ? v.phone : '+7 (';
			this.form.email 			= v ? v.email : null;
			this.form.addressesStreet 	= v && v.addressesStreet ? v.addressesStreet: (this.form.needEditAddress ? null : this.form.addressesStreet);
			this.form.addressesCity 	= v && v.addressesCity ? v.addressesCity: this.form.addressesCity;
			this.form.addressesZip 		= v && v.addressesZip ? v.addressesZip: this.form.addressesZip;
			this.form.addressShown 		= v && v.addressShown ? v.addressShown: this.form.addressShown;
			this.form.address 			= v && v.addressShown ? v.addressShown: this.form.addressShown;

			this.form.lastName 		= v.lastName ? v.lastName : this.form.lastName;
			this.form.firstName		= v.firstName ? v.firstName : this.form.firstName;
			this.form.middleName 	= v.middleName ? v.middleName : this.form.middleName;
			this.form.birthDate = v && v.birthDate ? v.birthDate : null;
			if ( v.lastName == 'null' ) this.form.lastName = null;
			if ( v.firstName == 'null' ) this.form.firstName = null;
			if ( v.middleName == 'null' ) this.form.middleName = null;
			if ( v.email == 'null' ) this.form.email = null;
			if ( v.phone == '+ 7(' ) this.form.phone = '+ 7(';
			if ( v.addressesStreet == 'null' ) this.form.addressesStreet = null;
			if ( v.addressesCity == 'null' ) this.form.addressesCity = null;
			if ( v.addressesZip == 'null' ) this.form.addressesZip = null;
			if ( v.addressShown == 'null' ) this.form.addressShown = null;
		},
		prepareData ( form ) {
			let data = _.pickBy(_.clone(form), _.identity);

			data.birthDate = moment(new Date(Date.UTC(data.birthDate.year, data.birthDate.month-1, data.birthDate.day))).format('YYYY-MM-DD');
			delete data.addressShown;

			return data;
		}
	}
}
</script>
