<template lang='pug'>
	.d-flex.flex-column.flex-md-row.select-birthday
		dropdown-general.w-100(:list="days" :size="'large'" @onChange='changeDay' :notChosen="true" :selecteDefault='selectedDay')
		dropdown-general.w-100.my-2.my-md-0.mx-md-2(:list="months" :size="'large'" @onChange='changeMonth' :notChosen="true" :selecteDefault='month.value == null ? 0 : month.value')
		dropdown-general.w-100(:list="years" :size="'large'" @onChange='changeYear' :notChosen="true" :selecteDefault='getYearIndex() ? getYearIndex() : 0')
</template>

<script>
import { localePicker } from '@/constants/config';
import {bus, locale} from '@/main';
import moment from 'moment-timezone';
import DropdownGeneral from '@/components/Dropdown/DropdownGeneral';

export default {
	name: 'PwSelectBirthday',
	components: {
		DropdownGeneral
	},
	props: {
		date: null
	},
	data: () => ({
		day		: {value: null, title: bus.$t('date.day')},
		month	: {value: null, title: bus.$t('date.month')},
		year	: {value: null, title: bus.$t('date.year')},
		days	: [],
		months	: [],
		years	: [],
	}),
	computed: {
		selectedDay() {
			return this.day.value == null ? 0 : this.day.value;
		}
	},
	methods: {
		setDate () {
			let yyyy = new Date().getFullYear() - 18;

			if (this.date) this.setDefaultDate();

			this.months		= [{value: null, title: this.$t('date.month')}, ..._.map(localePicker.find(x => x.id === locale).monthNamesFull, (m, i) => {return {value: i+1, title: m}})];
			this.years		= [..._.map(_.fill(new Array(100), true), (m,i) => {return {value: yyyy-100+i, title: yyyy-100+i}}), {value: null, title: this.$t('date.year')}].reverse();
			this.setDays();
		},
		setDays () {
			let days = 31;

			if ( this.month.value == 2 && this.year.value != null ) {
				days = 28;
				if ( this.year.value && this.year.value % 4 == 0 ) days = 29;
			}
			if ( this.month.value == 4 || this.month.value == 6 || this.month.value == 9 || this.month.value == 11 ) { days = 30; }

			this.days = [{value: null, title: this.$t('date.day')}, ..._.map(_.fill(Array(days), true), (m, i) => {return {value: i+1, title: i+1}})];

			if (this.day.value && this.day.value > days) this.day = {value: days, title: days};
		},
		changeDay(v) {
			this.day.value = v;
			this.trySuccess();
		},
		changeMonth(v) {
			this.month.value = _.find(this.months, m => m.title == v).value;
			this.setDays();
			this.trySuccess();
		},
		changeYear(v) {
			this.year.value = v;
			this.setDays();
			this.trySuccess();
		},

		getYearIndex() {
			let i = _.findIndex(this.years, y => y.value == this.year.value);

			return i == -1 ? 0 : _.findIndex(this.years, y => y.value == this.year.value);
		},

		setDefaultDate() {
			if ( _.isString(this.date) ) {
				let o = this.date.split('-');
				this.day = {value: Number(o[1]), title: Number(o[1])};
				this.month = {value: Number(o[0]), title: localePicker.find(x => x.id === locale).monthNamesFull[Number(o[0])-1]};
				this.year = {value: o[2], title: o[2]};
				return;
			}

			if ( moment([this.date.year, (this.date.month < 10 ? '0' : '' ) + this.date.month, (this.date.day < 10 ? '0' : '' ) + this.date.day].join('-')) != 'Invalid date' ) {
				this.day = {value: Number(this.date.day), title: Number(this.date.day)};
				this.month = {value: Number(this.date.month), title: localePicker.find(x => x.id === locale).monthNamesFull[Number(this.date.month)-1]};
				this.year = {value: this.date.year, title: this.date.year};
			}
		},
		trySuccess () {
			if ( this.day.value && this.month.value && this.year.value ) {
				if ( !moment(`${this.year.value}-${this.month.value < 10 ? '0'+this.month.value : this.month.value}-${this.day.value < 10 ? '0'+this.day.value : this.day.value}`)._isValid || isNaN(Number(this.year.value)) ) return this.$emit('undef');
				return this.$emit('success', {
					day: this.day.value < 10 ? '0' + this.day.value : this.day.value,
					month: this.month.value < 10 ? '0' + this.month.value : this.month.value,
					year: this.year.value
				});
			}

			return this.$emit('failed');
		}
	},
	watch: {
		date(v, o) {
			if ( v == null ) return;
			this.setDefaultDate();
		}
	},
	created () {
		this.setDate();
	}
}
</script>

<style lang='scss'>
@import '~@/assets/css/sass/_mixins.scss';

.select-birthday {
	.animated {
		.dropdown-menu {
			&.show {
				max-height: 160px!important;
			}
		}
	}

	@include respond-below(sm) {
		.dropdown-general {
			.dropdown-menu {
				top: -196px !important;
			}
		}
	}
}
</style>
