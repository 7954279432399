<template lang='pug'>
	.add-personal
		h2.mb-4 {{ $t('h1.finance') }}

		form-chouse-payment-system(:editedItem='item')

		.d-flex.flex-column.flex-md-row.card-basic.border.mt-4
			p.b3.text-grey.mb-3.mb-md-0.col-md-5.p-0(v-html="$t('support.haveQuestions')")
			a.d-block.col-md-7.ml-md-2.p-0(:href="`https://t.me/${$t('contacts.support_tg')}`" target="_blank")
				pw-btn.w-100.text-decoration-none(:text="$t('h1.telegram')" :icon="'icon-telegram'")
</template>

<script>
import FormChousePaymentSystem from '@/components/Form/FormChousePaymentSystem';
import PwBtn from '@/components/Buttons/PwBtn';

export default {
	name: 'AddPersonal',
	components: {
		FormChousePaymentSystem,
		PwBtn
	},
	props: {
		item: {
			type: Object,
			default: (() => {})
		}
	}
}
</script>

<style lang="scss">
@import '~@/assets/css/sass/_mixins.scss';

.add-personal {
	max-width: 551px;
	margin: 0 auto;
}
</style>
